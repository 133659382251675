
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Promised } from 'vue-promised';
import fm from 'front-matter';
import * as API from '@/api/github.api';
import {
  createDocument,
  marked,
  proxyGithubImages,
  setIframeAttributes,
  setVideoAttributes,
} from '@/utils/markdown';

import Grid from '@/components/grid/Grid';
import GridCell from '@/components/grid/GridCell';
import { ProcessEnvsModule } from '@/store/modules/process-envs.module';

interface DocAttributes {
  title?: string;
}

@Component({
  components: {
    Promised,
    Grid,
    GridCell,
  },
})
export default class HelpDocumentationViewer extends Vue {
  @Prop({ type: String, required: true })
  home!: string;

  get compiledMarkdown(): Promise<string> {
    const owner = ProcessEnvsModule.processEnvs.helpRepoOrg;
    const name = ProcessEnvsModule.processEnvs.helpRepoName;
    const branch = ProcessEnvsModule.processEnvs.helpRepoBranch;
    const file = this.$route.path;
    const args: Parameters<typeof API.getRepoFileContents> = [owner, name, file, branch];
    return API.getRepoFileContents(...args)
      .then(r => r.data)
      .then(md => {
        const res = fm<DocAttributes>(md);
        if (res.attributes.title) {
          this.$emit('update:title', res.attributes.title);
        }
        return res.body;
      })
      .then(md => marked(md, true))
      .then(html => {
        return proxyGithubImages(
          setIframeAttributes(setVideoAttributes(createDocument(html))),
          owner,
          name,
          file,
          branch,
        ).body.innerHTML;
      });
  }

  handleMarkdownClick(e: MouseEvent) {
    if (e.target) {
      const a = e.target as HTMLAnchorElement;
      if (a.tagName === 'A') {
        const link = a.getAttribute('href') || '';
        if (!/^http/.test(link)) {
          if (link.startsWith('#')) {
            return;
          } else {
            e.preventDefault();
            this.$router.push(link).then(() => {
              this.$emit('link', this.$route.path);
            });
          }
        }
      }
    }
  }
}
