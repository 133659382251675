import { AxiosPromise, AxiosRequestConfig } from 'axios';
import axios from '@/api/service';
import URLs from '@/api/service/urls';
import { User } from '@/types/users.types';

export interface GithubDocsRequestBody {
  repository: string;
  contentPath: string;
}

export const GSK_GITHUB_ORG = 'gsk-tech';

export interface GithubDocsResponseBody {
  name: string;
  path: string;
  sha: string;
  size: number;
  url: string;
  html_url: string;
  git_url: string;
  download_url: string;
  type: string;
  content: string;
  encoding: string;
  _links: {
    self: string;
    git: string;
    html: string;
  };
}

export function getGithubDocs(query: GithubDocsRequestBody): AxiosPromise<GithubDocsResponseBody> {
  return axios.post(URLs.GithubDocs, query);
}

export function getExternalDocumentation(link: string): AxiosPromise<any> {
  return axios.get(URLs.Proxy, { params: { url: link } });
}

export interface RepoFile {
  name: string;
  path: string;
  owner: User['mudId'];
  repo: string;
}

export function getRepoFiles(owner: string, repo: string): AxiosPromise<string[]> {
  return axios.get(URLs.GetGithubRepoFiles, {
    params: {
      owner,
      repo,
    },
  });
}

export function getRepoFileContents(
  owner: string,
  repo: string,
  path: string,
  version?: string,
): AxiosPromise<string> {
  const params: AxiosRequestConfig['params'] = {
    owner,
    repo,
    path,
  };

  if (version) {
    params.ref = version;
  }

  return axios.get(URLs.GetGithubRepoFileContents, {
    params: params,
  });
}
